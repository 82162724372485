import {onEnterViewPort} from "@elements/viewport-utils";

const selectors = {
    socialWallEmbedDiv: '.js-socialwall-embed',
    externalVideoWrapper: '.js-datareporter__external-video-wrapper',
    externalVideoOverlay: '.js-datareporter__external-video-overlay',
    externalScriptWrapper: '.js-datareporter__external-script-wrapper',
    externalScriptOverlay: '.js-datareporter__external-script-overlay',
    externalVideoPlaceholderWrapper: '.js-datareporter__external-video-placeholder-wrapper',
    externalVideoPlaceholder: '.js-datareporter__external-video-placeholder',
    externalVideoPlaceholderOverlay: '.js-datareporter__external-video-placeholder-overlay',
    isExternalMedia: '.js-datareporter__is-external-media',
    potentiallyExternalMedia: '.js-datareporter_potentially-external-media'
}

const embedScriptLink = '//static.snapshotboard.com/js/embed.js'
let wasTriggeredManually = false;

export function initInScope($scope) {
    doAsynchronously(handleConsent)

    function handleConsent() {
        // register the custom hooks
        if(typeof dr_cookiebanner_options !== 'undefined') {
            function handleEnabledCookies() {
                $scope.find(selectors.socialWallEmbedDiv).each(function () {
                    let $this = $(this);

                    // create a scripttag inside this div
                    let script = document.createElement('script');
                    script.setAttribute('src', embedScriptLink);
                    $this[0].appendChild(script);
                });

                $scope.find('.js-social-wall.js-social-wall-consent-needed').each(function () {
                    let $this = $(this);
                    $this.removeClass('js-social-wall-consent-needed');
                    let $link = $this.find('.js-social-wall__link');

                    let settings = {
                        offset: window.innerHeight / 2
                    };

                    onEnterViewPort($this, function () {
                        let script = document.createElement('script');
                        script.setAttribute('src', $this.data('src'));
                        $link.attr('href', $link.data('href'));
                        $this[0].appendChild(script);
                    }, settings);
                })

                $scope.find('.js-social-wall__link').each(function () {
                    let $this = $(this);
                    $this.attr('hidden', false);
                })

                $scope.find('.js-social-wall__consent-overlay').each(function () {
                    $(this).attr('hidden', true);
                })

                $scope.find(selectors.externalVideoWrapper).each(function () {
                    let $this = $(this);
                    let $iframe = $this.find('iframe');
                    let src = $iframe.data('src');
                    $iframe.attr('src', src);

                    // hide the overlay
                    let $overlay = $this.find(selectors.externalVideoOverlay);
                    $overlay.attr('hidden', true);
                })

                $scope.find(selectors.externalScriptWrapper).each(function () {
                    let $this = $(this);
                    let $script = $this.find('script');
                    let src = $script.data('src');
                    $script.attr('src', src);

                    let $iframe = $this.find('iframe');
                    src = $iframe.data('src');
                    $iframe.attr('src', src);

                    // hide the overlay
                    let $overlay = $this.find(selectors.externalScriptOverlay);
                    $overlay.attr('hidden', true);
                })

                $scope.find(selectors.externalVideoPlaceholderWrapper).each(function () {
                    let $this = $(this);
                    let $img = $this.find(selectors.externalVideoPlaceholder);
                    let src = $img.data('src');
                    $img.attr('src', src);

                    // hide the overlay
                    let $overlay = $this.find(selectors.externalVideoPlaceholderOverlay);
                    $overlay.attr('hidden', true);
                })

                // set dr cookies enabled data to true on body
                $('body').attr('data-dr-cookies-enabled', 'true')
            }

            $scope.find('.cc-btn').each(function () {
                $(this).on('click', function () {
                    handleConsentOverlaySubmit()
                })
            })

            if (dr_cookiebanner_options.dr_cookiesEnabled) {
                handleEnabledCookies()
            }

            // set callback hooks
            dr_cookiebanner_options.dr_onEnableCookies = function(enable) {
                if (wasTriggeredManually) {
                    const overlay = document.querySelector('.cc-window.cc-banner');
                }

                if (enable) {
                    handleEnabledCookies()
                }

                enableGTM();
            }

        } else {
            console.warn("dr_cookiebanner_options not found")
            doAsynchronously(handleConsent, 50);
        }
    }

    $scope.find(selectors.potentiallyExternalMedia).each(function () {
        let $this = $(this);

        // does it have any elements with class isExternalMedia?
        let $externalVideo = $this.find(selectors.isExternalMedia);
        if ($externalVideo.length == 0) {
            // add the overlay
            let $overlay = $this.find(selectors.externalVideoOverlay);
            $overlay.attr('hidden', true);
        }
    })

    $scope.find('.js-datareporter__open-overlay').each(function (button) {
        if($(this).data('handlesConsentOverlays') === 'true') {
            return;
        }

        $(this).data('handlesConsentOverlays', 'true');
        $(this).on('click', function (event) {
            event.preventDefault();
            openDatareporterConsentOverlay();
        });
    })
}

function doAsynchronously(callback, delay = 0) {
    setTimeout(function() {
        callback()
    }, delay)
}

function handleConsentOverlaySubmit() {
    if(wasTriggeredManually) {
        const overlay = document.querySelector('.cc-window.cc-banner');
        dr_cookiebanner_options.onPopupClose()
        overlay.classList.add('cc-invisible');
        overlay.style.display = 'none';
    }
}

export function openDatareporterConsentOverlay() {
    wasTriggeredManually = true;
    const overlay = document.querySelector('.cc-window.cc-banner');
    dr_cookiebanner_options.onPopupOpen()
    overlay.style.display = 'block';
    overlay.classList.remove('cc-invisible');

    console.log("opened datareporter overlay");
}